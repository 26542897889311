import React from "react";
import styled from "styled-components";
import { LabelFontCss, HintFontCss } from "../Utils/CssStyleTemplateUtil";
import { StaticImage } from "./StaticImage";

class TeamMemberRows extends React.Component {
  render() {
    let { members, itemCss } = this.props;

    return (
      <Wrapper>
        {members.map((member, idx) => (
          <MemberItem itemCss={itemCss}>
            <div className="avatar-container">
              <StaticImage path={"/about/"} name={member.avatar} />
            </div>
            <div className="name">{member.name}</div>
            <div className="position">{member.position}</div>
          </MemberItem>
        ))}
        {/* FAKE ITEM */}
        <MemberItem itemCss={itemCss} />
        <MemberItem itemCss={itemCss} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 360px) {
    justify-content: center;
  }
`;

const MemberItem = styled.div`
  max-width: 160px;
  width: 100%;

  & .avatar-container {
    margin-bottom: 10px;
  }

  & .name {
    text-align: center;
    font-size: 20px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    ${LabelFontCss}
    margin-bottom: 10px;
  }

  & .position {
    text-align: center;
    font-size: 14px;
    ${HintFontCss}
    color: #4e4e4e;
  }

  @media screen and (max-width: 1024px) {
    max-width: 130px;
  }
  @media screen and (max-width: 600px) {
    max-width: 110px;
  }
  @media screen and (max-width: 550px) {
    max-width: 90px;
  }
  @media screen and (max-width: 500px) {
    max-width: 130px;
  }

  ${props => props.itemCss || ""};
`;

export default TeamMemberRows;
