import React from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import OfficeCarousel from "../Components/OfficeCarousel";
import TeamMemberRows from "../Components/TeamMemberRows";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";

const TEAM_MEMBERS = [
  { name: "Rick", avatar: "Rick.png", position: "CEO" },
  { name: "Richie", avatar: "Richie.png", position: "CTO" },
  { name: "Sam", avatar: "Sam.png", position: "TSO" },
  { name: "Cara", avatar: "Cara.png", position: "Project Manager Lead" },
  { name: "Louis", avatar: "Louis.png", position: "UI / UX Designer" },
  { name: "Mylio", avatar: "Mylio.png", position: "Tech Lead" },
  { name: "Yahan", avatar: "Yahan.png", position: "Front-End Developer" },
  { name: "Ula", avatar: "Ula.png", position: "Front-End Developer" },
  { name: "Roy", avatar: "Roy.png", position: "Front-End Developer" },
  { name: "Guy", avatar: "Guy.png", position: "Front-End Developer" },
  { name: "Chien", avatar: "Chien.png", position: "Back-End Lead" },
  { name: "Kyle", avatar: "Kyle.png", position: "Back-End Developer" },
  { name: "Jamie", avatar: "Jamie.png", position: "Back-End Developer" },
  { name: "Lin", avatar: "lin.png", position: "PM / Sales" },
];

const GOOGLE_MAP_URL =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14456.618019067866!2d121.5166211!3d25.0627519!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa9535e65da02d957!2zUmV2dGVsVGVjaCDlv7vml4Xnp5HmioDogqHku73mnInpmZDlhazlj7g!5e0!3m2!1szh-TW!2stw!4v1585446639664!5m2!1szh-TW!2stw";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapWidth: 300,
      lang: "tw",
    };
  }

  componentDidMount() {
    this._getScreenWidth = setInterval(() => {
      let { mapWidth } = this.state;
      if (window.screen.width <= 900 && mapWidth !== window.screen.width) {
        this.setState({ mapWidth: window.screen.width });
      }
    }, 2000);

    if (window.location.href.indexOf("/en") === -1) {
      this.setState({ lang: "tw" });
    } else {
      this.setState({ lang: "en" });
    }
  }

  componentWillUnmount() {
    clearInterval(this._getScreenWidth);
  }

  render() {
    return (
      <Wrapper>
        {this._rowAboutPage()}
        {this._colAboutPage()}
      </Wrapper>
    );
  }

  _rowAboutPage = () => {
    return (
      <RowContainer>
        <div className="introduction-section">
          <div className="container">
            <h2 className="title">{s("about-title")}</h2>
            <p>{s("about-introduction")}</p>
          </div>
          <div className="container">
            <h2 className="title">{s("about-core-technology_title")}</h2>
            <p>{s("about-core-technology_introduction")}</p>
          </div>

          <div className="container" style={{}}>
            {this.state.lang === "tw" && (
              <>
                <h2
                  className="title"
                  style={{ fontSize: 26, marginBottom: 16 }}
                >
                  相關連結
                </h2>
                <div className="row" style={{ marginBottom: 12 }}>
                  <Icon.Bookmark size={20} color="#4E4E4E" />
                  <p style={{ textAlign: "left", marginLeft: 5 }}>
                    <a
                      href="https://consult.revtel.tech/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      target="_blank"
                    >
                      軟體顧問服務 (https://consult.revtel.tech/)
                    </a>
                  </p>
                </div>
                <div className="row" style={{ marginBottom: 12 }}>
                  <Icon.Bookmark size={20} color="#4E4E4E" />
                  <p style={{ textAlign: "left", marginLeft: 5 }}>
                    <a
                      href="https://www.nfcmaker.io/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      target="_blank"
                    >
                      NFC 開發服務 (https://www.nfcmaker.io/)
                    </a>
                  </p>
                </div>
                <div className="row" style={{ marginBottom: 12 }}>
                  <Icon.Bookmark size={20} color="#4E4E4E" />
                  <p style={{ textAlign: "left", marginLeft: 5 }}>
                    <a
                      href="https://www.nfctogo.com/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      target="_blank"
                    >
                      NFC 名片卡 (https://www.nfctogo.com/)
                    </a>
                  </p>
                </div>
                <div style={{ width: 1, height: 16 }}> </div>
              </>
            )}

            <h2 className="title" style={{ fontSize: 26, marginBottom: 16 }}>
              {s("about-contact_title")}
            </h2>
            <div className="row" style={{ marginBottom: 12 }}>
              <Icon.Call size={20} color="#4E4E4E" />
              <p style={{ textAlign: "left", marginLeft: 5 }}>
                <a
                  href="tel:+886225578895"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  (02)2557-8895
                </a>
              </p>
            </div>
            <div className="row" style={{ marginBottom: 12 }}>
              <Icon.Email size={20} color="#4E4E4E" />
              <p style={{ textAlign: "left", marginLeft: 5 }}>
                <a
                  href="mailto:contact@revteltech.com"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  contact@revteltech.com
                </a>
              </p>
            </div>
            <div className="row" style={{ marginBottom: 12 }}>
              <Icon.Location size={20} color="#4E4E4E" />
              <p style={{ textAlign: "left", marginLeft: 5 }}>
                <a
                  href="https://g.page/RevtelTech?share"
                  target="_blank"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  {s("about-contact_address")}
                </a>
              </p>
            </div>
          </div>
          <div className="map-container">
            <iframe
              src={GOOGLE_MAP_URL}
              frameborder="0"
              width={300}
              height={250}
            />
          </div>
        </div>
        <div className="about-section">
          <img src="/images/revtel-brand.png" style={{ width: "100%" }} />
          <img src="/images/revtel-office.png" style={{ width: "100%" }} />
        </div>
      </RowContainer>
    );
  };

  _colAboutPage = () => {
    return (
      <ColContainer>
        <img src="/images/revtel-brand.png" style={{ width: "100%" }} />
        <div className="about-container">
          <h2 className="title">{s("about-title")}</h2>
          <p>{s("about-introduction")}</p>
        </div>

        <div className="contact-container">
          {this.state.lang === "tw" && (
            <>
              <h2 className="title" style={{ fontSize: 26, marginBottom: 16 }}>
                相關連結
              </h2>
              <div className="row" style={{ marginBottom: 12 }}>
                <Icon.Bookmark size={20} color="#4E4E4E" />
                <p style={{ textAlign: "left", marginLeft: 5 }}>
                  <a
                    href="https://consult.revtel.tech/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    target="_blank"
                  >
                    軟體顧問服務 (https://consult.revtel.tech/)
                  </a>
                </p>
              </div>
              <div className="row" style={{ marginBottom: 12 }}>
                <Icon.Bookmark size={20} color="#4E4E4E" />
                <p style={{ textAlign: "left", marginLeft: 5 }}>
                  <a
                    href="https://www.nfcmaker.io/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    target="_blank"
                  >
                    NFC 開發服務 (https://www.nfcmaker.io/)
                  </a>
                </p>
              </div>
              <div className="row" style={{ marginBottom: 12 }}>
                <Icon.Bookmark size={20} color="#4E4E4E" />
                <p style={{ textAlign: "left", marginLeft: 5 }}>
                  <a
                    href="https://www.nfctogo.com/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    target="_blank"
                  >
                    NFC 名片卡 (https://www.nfctogo.com/)
                  </a>
                </p>
              </div>
              <div style={{ width: 1, height: 16 }}> </div>
            </>
          )}
          <h2 className="title">{s("about-contact_title")}</h2>
          <div className="row">
            <Icon.Call size={20} color="#4E4E4E" />
            <p style={{ textAlign: "left", marginLeft: 5 }}>
              <a
                href="tel:+886225578895"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                (02)2557-8895
              </a>
            </p>
          </div>
          <div className="row">
            <Icon.Email size={20} color="#4E4E4E" />
            <p style={{ textAlign: "left", marginLeft: 5 }}>
              <a
                href="mailto:contact@revteltech.com"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                contact@revteltech.com
              </a>
            </p>
          </div>
          <div className="row">
            <Icon.Location size={20} color="#4E4E4E" />
            <p style={{ textAlign: "left", marginLeft: 5 }}>
              <a
                href="https://g.page/RevtelTech?share"
                target="_blank"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {s("about-contact_address")}
              </a>
            </p>
          </div>
        </div>
        <div className="map-container">
          <iframe
            src={GOOGLE_MAP_URL}
            frameborder="0"
            width={this.state.mapWidth}
            height={400}
          />
        </div>
      </ColContainer>
    );
  };
}

const Wrapper = styled.div``;

const RowContainer = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 420px;
    flex-grow: 0;
    flex-shrink: 0;

    & div {
      & h2 {
        letter-spacing: 2px;
        font-size: 26px;
        ${HeaderFontCss}
        margin-bottom: 20px;
      }

      & p {
        font-size: 14px;
        ${ParagraphFontCss}
        white-space: pre-wrap;
        text-align: justify;
        line-height: 20px;
        letter-spacing: 0.4px;
      }

      & button {
        margin-top: 20px;
        align-self: flex-start;
      }
    }

    & div.row {
      display: flex;
      flex-direction: row;
      margin-bottom: 7px;
    }
  }

  .about-section {
    background-color: #f1f1f1;
    flex-grow: 1;
    color: #4e4e4e;

    & div.container {
      margin-bottom: 60px;

      & h2 {
        font-size: 26px;
        ${HeaderFontCss}
        margin-bottom: 20px;
      }

      & p {
        font-size: 14px;
        ${ParagraphFontCss}
        color: #4e4e4e;
        white-space: pre-wrap;
        text-align: justify;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
  }
`;

const ColContainer = styled.div`
  background-color: #f1f1f1;
  color: #4e4e4e;

  @media screen and (min-width: ${900 + 1}px) {
    display: none;
  }

  .logo-container {
    padding: 18px 23px;
  }

  .carousel-container {
    & p {
      padding: 25px 40px;
    }
  }

  .about-container {
    padding: 25px 40px;
  }

  .team-container {
    padding: 25px 40px;
  }

  .tech-container {
    padding: 25px 40px;
  }

  .contact-container {
    padding: 25px 40px;

    & button {
      margin-top: 40px;
    }

    & div.row {
      display: flex;
      flex-direction: row;
      margin-bottom: 7px;
    }
  }

  .map-container {
    padding: 15px 0px 0px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  & h2 {
    letter-spacing: 2px;
    font-size: 26px;
    ${HeaderFontCss}
    margin-bottom: 15px;
  }

  & p {
    font-size: 14px;
    ${ParagraphFontCss}
    white-space: pre-wrap;
    text-align: justify;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  @media screen and (max-width: 500px) {
    .logo-container {
      display: none;
    }

    .carousel-container {
      p {
        padding: 30px 25px;
      }
    }

    .about-container {
      padding: 30px 25px;
    }

    .team-container {
      padding: 30px 25px;
    }

    .tech-container {
      padding: 30px 25px;
    }

    .contact-container {
      padding: 30px 25px;
      display: flex;
      flex-direction: column;

      & p {
        text-align: center;
      }

      & button {
        align-self: center;
      }
    }

    .map-container {
      padding: 15px 0px 0px;
    }

    & h2 {
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 15px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }
`;

export default withPage(AboutPage);
